import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

export default function SetExecutionTimeDialog({ open, onClose, defaultTime, onSave }) {
  const [time, setTime] = useState(defaultTime);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Execution Time</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Label htmlFor="execution-time">Set Time In Minutes</Label>
          <Input
            id="execution-time"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            placeholder="Enter time"
            className="appearance-none"
          />
        </div>
        <DialogFooter>
          <Button onClick={() => onSave(time)}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
